import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import loadable from "@loadable/component";

import { AuthContext } from "./contexts/UserContext";
import MainLayoutRoutes from "./routes/MainRoutes";

import RequireAuth from "./routes/RequireAuth";
import { fetchToken } from "./firebase";
// import Event from "./pages/event";
// import Media from "./pages/media";

import AlertPopup from "./components/AlertPopup";

import "./assets/css/app.css";
import "./assets/css/custom.css";
import "./assets/css/libs.bundle.css";
import "./assets/css/theme.bundle.css";
// import Favorites from "./pages/favorites";
// import FavMedia from "./pages/favMedia";
// import Signup from "./pages/signup";
// import Login from "./pages/login";
// import InactiveEvent from "./pages/inactiveEvent";
// import ExampleEvent from "./pages/exampleEvent";
// import ExampleMedia from "./pages/exampleMedia";
const Favorites = loadable(() => import("./pages/favorites"));
const Signup = loadable(() => import("./pages/signup"));
const Login = loadable(() => import("./pages/login"));
const InactiveEvent = loadable(() => import("./pages/inactiveEvent"));
const ExampleEvent = loadable(() => import("./pages/exampleEvent"));
const ExampleMedia = loadable(() => import("./pages/exampleMedia"));
const FavMedia = loadable(() => import("./pages/favMedia"));
const Event = loadable(() => import("./pages/event"));
const Media = loadable(() => import("./pages/media"));
const PrintShareEvent = loadable(() => import("./pages/printShareEvent"));

function App() {
  const { currentUser } = useContext(AuthContext);
  const [isTokenFound, setTokenFound] = useState(false);

  useEffect(() => {
    if (!isTokenFound && currentUser) fetchToken(setTokenFound);
  }, []);

  return (
    <>
      <BrowserRouter>
        <AlertPopup></AlertPopup>
        <Routes>
          <Route
            path="/share-event/:eventAddress"
            element={<PrintShareEvent />}
          ></Route>
          <Route path="/example-event" element={<ExampleEvent />}></Route>
          <Route
            path="/example-event/media/:mediaID"
            element={<ExampleMedia />}
          ></Route>
          <Route
            path="/activate-event/:eventAddress"
            element={
              <RequireAuth redirectTo="/login">
                <InactiveEvent />
              </RequireAuth>
            }
          />
          <Route
            path="/signup"
            element={
              currentUser ? (
                <Navigate to="/events"></Navigate>
              ) : (
                <Signup></Signup>
              )
            }
          />
          <Route
            path="/login"
            element={
              currentUser ? <Navigate to="/events"></Navigate> : <Login></Login>
            }
          />
          <Route path="/favorites" element={<Favorites />}></Route>
          <Route path="/events/:eventAddress" element={<Event />}></Route>
          <Route
            path="/events/:eventAddress/media/:mediaID"
            element={<Media />}
          ></Route>
          <Route
            path="/favorites/:eventAddress/media/:mediaID"
            element={<FavMedia />}
          ></Route>
          <Route path="*" element={<MainLayoutRoutes />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
