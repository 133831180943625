import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import loadable from "@loadable/component";

import Navbar from "../layouts/navbar";
// import Home from "../pages/home";
import RequireAuth from "../routes/RequireAuth";
// import CreateEvent from "../pages/createEvent";
// import Events from "../pages/events";
// import Favorites from "../pages/favorites";
import Footer from "../layouts/footer";
// import TermsOfService from "../pages/terms-services";
// import Pricing from "../pages/pricing";
// import AccountSettings from "../pages/accountSettings";
// import EventSettings from "../pages/eventSettings";
// import Event from "../pages/event";
// import DownloadPhotoAlbum from "../pages/downloadPhotoAlbum";
// import SelectPlan from "../pages/selectPlan";
// import NotFound from "../pages/NotFound";
// import PaymentFailure from "../pages/paymentFailure";
// import PaymentSuccess from "../pages/paymentSuccess";
// import VerifyUser from "../pages/verifyUser";
// import ResetPassword from "../pages/ResetPassword";
import { AuthContext } from "../contexts/UserContext";
// import Blog from "../pages/blog";
import ScrollToTop from "../utils/ScrollToTop";
import RequireStripe from "./RequireStripe";
// import Article from "../pages/article";

const NotFound = loadable(() => import("../pages/NotFound"));

const VerifyUser = loadable(() => import("../pages/verifyUser"));

const Home = loadable(() => import("../pages/home"));

const CreateEvent = loadable(() => import("../pages/createEvent"));

const Events = loadable(() => import("../pages/events"));

const Favorites = loadable(() => import("../pages/favorites"));

const Pricing = loadable(() => import("../pages/pricing"));

const AccountSettings = loadable(() => import("../pages/accountSettings"));

const EventSettings = loadable(() => import("../pages/eventSettings"));

const Event = loadable(() => import("../pages/event"));

const DownloadPhotoAlbum = loadable(() =>
  import("../pages/downloadPhotoAlbum")
);

const SelectPlan = loadable(() => import("../pages/selectPlan"));

const PaymentFailure = loadable(() => import("../pages/paymentFailure"));

const PaymentSuccess = loadable(() => import("../pages/paymentSuccess"));

const ResetPassword = loadable(() => import("../pages/ResetPassword"));

const Blog = loadable(() => import("../pages/blog"));

const TermsOfService = loadable(() => import("../pages/terms-services"));

const Article = loadable(() => import("../pages/article"));

export default function MainRoutes() {
  const { notificationsOpen } = useContext(AuthContext);
  return (
    <React.Fragment>
      <ScrollToTop />
      <Navbar></Navbar>
      <div
        className={`${notificationsOpen && "notifications-open"}`}
        style={!notificationsOpen ? { minHeight: "calc(100vh - 135px)" } : {}}
      >
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:postid" element={<Article />} />
          <Route
            path="/checkout/failure"
            element={
              <RequireAuth redirectTo="/login">
                <PaymentFailure />
              </RequireAuth>
            }
          />
          <Route
            path="/checkout/success"
            element={
              <RequireAuth redirectTo="/login">
                <PaymentSuccess />
              </RequireAuth>
            }
          />
          <Route
            path="/event"
            element={
              <RequireAuth redirectTo="/login">
                <Event />
              </RequireAuth>
            }
          />
          <Route
            path="/account-settings"
            element={
              <RequireAuth redirectTo="/login">
                <RequireStripe>
                  <AccountSettings />
                </RequireStripe>
              </RequireAuth>
            }
          />
          <Route
            path="/create-event"
            element={
              <RequireAuth redirectTo="/login">
                <CreateEvent />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/events"
            element={
                <Events />
            }
          ></Route>
          <Route
            path="/favorites"
            element={
              <RequireAuth redirectTo="/login">
                <Favorites />
              </RequireAuth>
            }
          ></Route>
          <Route path="/pricing" element={<Pricing />}></Route>
          <Route path="/user/verify" element={<VerifyUser />}></Route>
          <Route
            path="/user/reset-password"
            element={<ResetPassword />}
          ></Route>
          <Route
            path="/terms"
            element={
              <RequireAuth redirectTo="/login">
                <TermsOfService />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/select-plan/:eventAddress"
            element={
              <RequireAuth redirectTo="/login">
                <RequireStripe>
                  <SelectPlan />
                </RequireStripe>
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/events/:eventAddress/settings"
            element={
              <RequireAuth redirectTo="/login">
                <EventSettings />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/events/:eventAddress/download-album/:requestID"
            element={<DownloadPhotoAlbum />}
          ></Route>
        </Routes>
      </div>
      <Footer></Footer>
    </React.Fragment>
  );
}
