import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export default function ConfirmModal({
  open,
  title,
  description,
  onCancel,
  onConfirm,
}) {
  return (
    <Modal centered isOpen={open}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{description}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={onConfirm}>
          Confirm
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
