import React from "react";
import { Alert } from "reactstrap";

import useAlert from "../hooks/userAlert";
// import "../assets/styles/app.css";

export default function AlertPopup() {
  const { text, type } = useAlert();

  if (text && type) {
    return (
      <Alert
        className="alert-fixed"
        color={type}
        style={{
          position: "fixed",
          top: 0,
          borderRadius: 0,
        }}
      >
        {text}
      </Alert>
    );
  } else {
    return <></>;
  }
}
