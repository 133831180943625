import React from "react";
import ConfirmationDialog from "../components/ConfirmModal";

export const ConfirmationDialogContext = React.createContext({});

const ConfirmationDialogProvider = ({ children }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogConfig, setDialogConfig] = React.useState({});

    const openDialog = ({ title, description, actionCallback }) => {
        setDialogOpen(true);
        setDialogConfig({ title, description, actionCallback });
    };

    const resetDialog = () => {
        setDialogOpen(false);
        setDialogConfig({});
    };

    const onConfirm = () => {
        resetDialog();
        dialogConfig.actionCallback(true);
    };

    const onCancel = () => {
        resetDialog();
        dialogConfig.actionCallback(false);
    };

    return (
        <ConfirmationDialogContext.Provider value={{ openDialog }}>
            <ConfirmationDialog
                open={dialogOpen}
                title={dialogConfig?.title}
                description={dialogConfig?.description}
                onConfirm={onConfirm}
                onCancel={onCancel}
            />
            {children}
        </ConfirmationDialogContext.Provider>
    );
};

export default ConfirmationDialogProvider;