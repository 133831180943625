import React, { useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import { NavLink, useNavigate } from "react-router-dom";
import { getNotifications as getNotificationsApi } from "../api/user";
import BellIcon from "../assets/img/custom/bell.svg";
import { AuthContext } from "../contexts/UserContext";
import { onMessageListener } from "../firebase";
import { useComponentVisible } from "../hooks/useComponentVisible";

export default function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const { currentUser, setnotificationsOpen } = useContext(AuthContext);
  const navigate = useNavigate();

  const {
    ref: notificationsRef,
    isComponentVisible: showNotifications,
    setIsComponentVisible: setShowNotifications,
  } = useComponentVisible(false);

  useEffect(() => {
    if (currentUser) fetchNotifications();
  }, [showNotifications]);

  useEffect(() => {
    setnotificationsOpen(showNotifications);
  }, [showNotifications]);

  useEffect(() => {
    let count = 0;
    notifications.forEach((notification) => {
      if (notification.read === false) count += 1;
    });
    setUnreadNotificationsCount(count);
  }, [notifications]);

  const fetchNotifications = () => {
    if (currentUser) {
      getNotificationsApi().then((notifications) => {
        setNotifications(notifications);
      });
    }
  };

  onMessageListener()
    .then(() => {
      if (currentUser) fetchNotifications();
    })
    .catch((err) => console.log("failed: ", err));

  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }

  const [windowWidth, setwindowWidth] = useState(getWindowDimensions().width);

  useEffect(() => {
    function handleResize() {
      setwindowWidth((prev) => {
        if (prev != getWindowDimensions().width) {
          setShowNotifications(false);
        }
        return getWindowDimensions().width;
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleOnClickNotification(notification) {
    if (notification.data.url) {
      const loc = new URL(notification.data.url);
      navigate(loc.pathname);
      setShowNotifications(false);
    }
  }

  return (
    <div>
      <li className="nav-item dropdown position-relative d-none d-lg-block">
        <a
          className="nav-link pointer"
          onClick={() => setShowNotifications(!showNotifications)}
        >
          <div
            style={{
              cursor: "pointer",
              position: "absolute",
              background: "#DF4759",
              borderRadius: "10px",
              width: "12px",
              height: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: "6px",
              left: "2.2rem",
            }}
          >
            <span
              style={{ fontSize: "7px", color: "white" }}
              className="navbar-toggle"
            >
              {unreadNotificationsCount}
            </span>
          </div>
          <img
            alt="..."
            src={BellIcon}
            style={{ height: "24px", width: "24px" }}
            className="navbar-toggle"
          />
        </a>
        {showNotifications && (
          <div
            // ref={notificationsRef}
            ref={(el) => (notificationsRef.current[0] = el)}
            id="lg-notifications-wrapper"
            className="py-5 px-5"
            style={{
              backgroundColor: "#fff",
              border: "0 solid rgba(22, 28, 45, 0.15)",
              borderRadius: "0.375rem",
              boxShadow: "0 0.5rem 1.5rem rgb(22 28 45 / 10%)",
              color: "#161c2d",
              margin: 0,
              listStyle: "none",
              width: "280px",
              position: "absolute",
              overflow: "scroll",
              zIndex: 3,
              right: 0,
              maxHeight: "89vh",
            }}
            aria-labelledby="navbarPages"
          >
            <h4
              style={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "21px",
              }}
              className="mb-5"
            >
              Notifications
            </h4>
            <div className="border-bottom">
              {notifications.map((notification) => (
                <div
                  onClick={() => handleOnClickNotification(notification)}
                  className="notification d-flex align-items-start pointer mb-4"
                >
                  <div className="position-relative mt-2">
                    {!notification.read ? (
                      <>
                        <div
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            background: "#3D5DE2",
                            borderRadius: "10px",
                            width: "10px",
                            height: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            top: "-8px",
                            left: "20px",
                          }}
                        ></div>
                        <div
                          style={{
                            background: "#F1F4F8",
                            display: "inline",
                            padding: "8px 10px",
                            borderRadius: "50%",
                          }}
                        >
                          <img
                            alt="..."
                            src={BellIcon}
                            style={{
                              height: "14px",
                              width: "14px",
                              color: "white",
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            background: "#F1F4F8",
                            display: "inline",
                            padding: "8px 10px",
                            borderRadius: "50%",
                          }}
                        >
                          <img
                            alt="..."
                            src={BellIcon}
                            style={{
                              height: "14px",
                              width: "14px",
                              color: "white",
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mx-4 d-flex flex-column">
                    <small className="p-0 m-0">{notification.body}</small>
                    <small style={{ color: "#506690" }}>
                      <Moment fromNow>{notification.sentAt}</Moment>
                    </small>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-4">
              {/* <NavLink to="/" className="m-0 p-0"> */}
              <small
                style={{
                  fontWeight: 600,
                  lineHeight: "18px",
                  color: "#3D5DE2",
                  textDecoration: "underline",
                }}
              >
                See All Notifications
              </small>
              {/* </NavLink> */}
            </div>
          </div>
        )}
      </li>
      <div className="d-block d-lg-none">
        <div>
          <a
            className="nav-link"
            onClick={() => setShowNotifications(!showNotifications)}
          >
            <div
              style={{
                cursor: "pointer",
                position: "absolute",
                background: "#DF4759",
                borderRadius: "10px",
                width: "12px",
                height: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                top: "6px",
                right: "4.2rem",
              }}
            >
              <span style={{ fontSize: "7px", color: "white" }}>
                {unreadNotificationsCount}
              </span>
            </div>
            <img
              src={BellIcon}
              style={{ height: "24px", width: "24px" }}
              alt="..."
            />
          </a>
          {showNotifications && (
            <div
              ref={(el) => (notificationsRef.current[1] = el)}
              // ref={notificationsRef}
              id="sm-notifications-wrapper"
              className="px-5 pt-5"
              style={{
                overflow: "scroll",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                background: "#fff",
                zIndex: 150,
              }}
            >
              <div className="d-flex justify-content-between align-items-center mb-5">
                <p
                  className="m-0"
                  style={{
                    fontWeight: 600,
                    fonSsize: "16px",
                    lineHeight: "21px",
                    color: "#161C2D",
                  }}
                >
                  Notification
                </p>
                <span
                  className="fe fe-x"
                  onClick={() => setShowNotifications(false)}
                ></span>
              </div>
              {notifications.map((notification) => (
                <div>
                  <div
                    onClick={() => handleOnClickNotification(notification)}
                    className="notification d-flex align-items-start pointer mb-5"
                  >
                    <div className="position-relative mt-2">
                      {!notification.read ? (
                        <>
                          <div
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              background: "#3D5DE2",
                              borderRadius: "10px",
                              width: "10px",
                              height: "10px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              top: "-8px",
                              left: "20px",
                            }}
                          ></div>
                          <div
                            style={{
                              background: "#F1F4F8",
                              display: "inline",
                              padding: "8px 10px",
                              borderRadius: "50%",
                            }}
                          >
                            <img
                              alt="..."
                              src={BellIcon}
                              style={{
                                height: "14px",
                                width: "14px",
                                color: "white",
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              background: "#F1F4F8",
                              display: "inline",
                              padding: "8px 10px",
                              borderRadius: "50%",
                            }}
                          >
                            <img
                              alt="..."
                              src={BellIcon}
                              style={{
                                height: "14px",
                                width: "14px",
                                color: "white",
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="mx-4 d-flex flex-column">
                      <small className="p-0 m-0">{notification.body}</small>
                      <small style={{ color: "#506690" }}>
                        <Moment fromNow>{notification.sentAt}</Moment>
                      </small>
                    </div>
                  </div>
                </div>
              ))}
              <div className="text-center mt-4">
                {/* <NavLink to="/" className="m-0 p-0"> */}
                <small
                  style={{
                    fontWeight: 600,
                    lineHeight: "18px",
                    color: "#3D5DE2",
                    textDecoration: "underline",
                  }}
                >
                  See All Notifications
                </small>
                {/* </NavLink> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
