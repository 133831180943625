import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import App from "./App";
import { AlertProvider } from "./contexts/AlertContext";
import AuthProvider from "./contexts/UserContext";
import ConfirmProvider from "./contexts/ConfirmContext";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
import EventConTextProvider from "./contexts/EventContext";

// let stripePromise;
// export const stripePromise = loadStripe(
//   process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
// );
// const getStripe = () => {
//   if (!stripePromise) {
//     stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
//   }
//   return stripePromise;
// };
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <Elements stripe={getStripe()}>
  <AlertProvider>
    <ConfirmProvider>
      <AuthProvider>
        <EventConTextProvider>
          <App />
        </EventConTextProvider>
      </AuthProvider>
    </ConfirmProvider>
  </AlertProvider>
  // </Elements>
);
