import React, { useState } from "react";

import { useLocalStorage } from "../hooks/useLocalStorage";
import { checkLoggedIn } from "../api/user";

export const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useLocalStorage("user", null);
  const [loading, setLoading] = useState(false);
  const [notificationsOpen, setnotificationsOpen] = useState(false);

  // useEffect(() => {
  //   checkAuth();
  // }, []);

  function checkAuth() {
    setLoading(true);
    checkLoggedIn().then((activeUser) => {
      if (activeUser) {
        setCurrentUser(activeUser);
        setLoading(false);
      } else {
        setCurrentUser(null);
        setLoading(false);
      }
    });
  }

  const value = {
    currentUser,
    loading,
    setUser: setCurrentUser,
    notificationsOpen,
    setnotificationsOpen,
    checkAuth,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
export default AuthProvider;
