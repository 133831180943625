import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { subscribeToNotifications } from "./api/user"

const firebaseConfig = {
    apiKey: "AIzaSyCkimt5QfqLVw1aBq3APx7teFPgJxeZBKw",
    authDomain: "memolast-f432e.firebaseapp.com",
    projectId: "memolast-f432e",
    storageBucket: "memolast-f432e.appspot.com",
    messagingSenderId: "995579162202",
    appId: "1:995579162202:web:dee7a10df9dabbb9cfb263",
    measurementId: "G-T1MHC5K6TE"
};
let firebaseApp = null;
let messaging = null;
try {
    firebaseApp = initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);
} catch (error) {

}



export const fetchToken = (setTokenFound) => {
    return getToken(messaging, { vapidKey: 'BDYE2CebjLSO-GWnjxFufoBfU9662MNfkOxaN_gbezJ8Rxtdb2wrcqcOp_houaHWk2YdbkIzhFJd6hy2u_dxsg4' }).then((currentToken) => {
        if (currentToken) {
            setTokenFound(true);
            subscribeToNotifications({ fcmToken: currentToken })
        } else {
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });