import { axiosClient } from "./axios";

export async function signup({ name, email, password }) {
  try {
    const response = await axiosClient.post(`/users/register`, {
      name,
      email,
      password,
    });
    return response.data.data.user;
  } catch (error) {
    throw error;
  }
}

export async function login({ email, password }) {
  try {
    const response = await axiosClient.post(`/users/login`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function changePassword({ currentPass, newPass }) {
  try {
    const response = await axiosClient.put(`/users/password`, {
      oldPass: currentPass,
      newPass,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function checkLoggedIn() {
  try {
    const response = await axiosClient.post(`/users/check-login`, {});
    return response.data.data.user;
  } catch (error) {
    throw error;
  }
}

export async function logout() {
  try {
    const response = await axiosClient.post(`/users/logout`, {});
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export async function getEventsOfUser({
  type = "All",
  sortBy,
  sortOrder,
  skip,
  limit,
}) {
  try {
    const response = await axiosClient.get(
      `/users/events?type=${type}&skip=${skip}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`
    );
    return response.data.data.events;
  } catch (error) {
    throw error;
  }
}

export async function getFavorite({ favoriteID }) {
  try {
    const response = await axiosClient.get(`/users/favorites/${favoriteID}`);
    return response.data.data.favorite;
  } catch (error) {
    throw error;
  }
}

export async function getFavorites({ skip, limit }) {
  try {
    const response = await axiosClient.get(
      `/users/favorites?skip=${skip}&limit=${limit}`
    );
    return response.data.data.favorites;
  } catch (error) {
    throw error;
  }
}

export async function getFavoritesOfEvent({ eventID }) {
  try {
    const response = await axiosClient.get(
      `/users/favorites?eventID=${eventID}`
    );
    return response.data.data.favorites;
  } catch (error) {
    throw error;
  }
}

export async function getPrevFavorites({ favoriteID, count = 10 }) {
  try {
    const response = await axiosClient.get(
      `/users/favorites/${favoriteID}/prev?count=${count}`
    );
    return response.data.data.favorites;
  } catch (error) {
    throw error;
  }
}

export async function getNextFavorites({ favoriteID, count = 10 }) {
  try {
    const response = await axiosClient.get(
      `/users/favorites/${favoriteID}/next?count=${count}`
    );
    return response.data.data.favorites;
  } catch (error) {
    throw error;
  }
}

export async function updateProfilePicRequest({ contentType }) {
  try {
    let uri = `/users/profile-upload`;
    const response = await axiosClient.post(uri, { contentType });
    return response.data.data.signedUrl;
  } catch (error) {
    throw error;
  }
}

export async function uploadProfilePicture({ signedUrl, file }) {
  try {
    const response = await axiosClient.put(signedUrl.url, file, {
      headers: { "Content-Type": file.type },
      withCredentials: false,
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getCards() {
  try {
    let uri = `/users/cards`;
    // if (lastObj) {
    //   uri += `&lastObj=${lastObj}`
    // }
    const response = await axiosClient.get(uri);
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export async function setupIntent() {
  try {
    let uri = `/users/setup-intent`;
    const response = await axiosClient.post(uri, {});
    return response.data.data.setupIntent;
  } catch (error) {
    throw error;
  }
}

export async function updatePaymentMethod({ paymentMethodId }) {
  try {
    let uri = `/users/payment-method?paymentMethodId=${paymentMethodId}`;
    const response = await axiosClient.put(uri, {});
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export async function removePaymentMethod({ paymentMethodId }) {
  try {
    let uri = `/users/payment-method?paymentMethodId=${paymentMethodId}`;
    const response = await axiosClient.delete(uri, {});
    return response.data.data;
  } catch (error) {
    throw error;
  }
}

export async function getFreeEventsCount() {
  try {
    let uri = `/users/free-event-count`;
    const response = await axiosClient.get(uri);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getEventsCount() {
  try {
    let uri = `/users/events/count`;
    const response = await axiosClient.get(uri);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function subscribeToNotifications({ fcmToken }) {
  try {
    let uri = `/users/notifications`;
    const response = await axiosClient.post(uri, { fcmToken });
    return response.data.data.setupIntent;
  } catch (error) {
    throw error;
  }
}

export async function getNotifications() {
  try {
    let uri = `/users/notifications`;
    const response = await axiosClient.get(uri);
    return response.data.data.notifications;
  } catch (error) {
    throw error;
  }
}

export async function markNotificationsAsRead() {
  try {
    let uri = `/users/notifications/read`;
    const response = await axiosClient.post(uri);
    return response.data.data.setupIntent;
  } catch (error) {
    throw error;
  }
}

export async function removeUserFromEvent({ eventAddress, userID }) {
  try {
    const response = await axiosClient.delete(
      `/events/${eventAddress}/users?userID=${userID}`
    );
    return response;
  } catch (err) {
    throw err;
  }
}

export async function updateUserStatus({ eventAddress, userID, action }) {
  try {
    let uri = `/events/${eventAddress}/users`;
    const response = await axiosClient.post(uri, { userID, action });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function verifyUser({ userID, token }) {
  try {
    const response = await axiosClient.put(`/users/verify`, {
      userID,
      token,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function sendVerificationEmail() {
  try {
    const response = await axiosClient.post(`/users/verify`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function sendPasswordResetEmail({ email }) {
  try {
    const response = await axiosClient.post(`/users/password`, {
      email,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function resetPassword({ userID, token, password }) {
  try {
    const response = await axiosClient.delete(`/users/password`, {
      data: { userID, token, password },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteUser() {
  try {
    const response = await axiosClient.delete("/users");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSubscription() {
  try {
    const response = await axiosClient.get("/users/subscription");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function cancelSubscriptionImmediately() {
  try {
    const response = await axiosClient.delete("/users/subscription");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function cancelSubscriptionAtEnd() {
  try {
    const response = await axiosClient.post("/users/subscription");
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getInvoices() {
  try {
    const response = await axiosClient.get("/users/invoices");
    return response.data;
  } catch (error) {
    throw error;
  }
}
