import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

// import "../assets/styles/app.css";
import { AuthContext } from "../contexts/UserContext";
import { logout as logoutApi } from "../api/user";
import useAlert from "../hooks/userAlert";
import Logo from "../assets/img/custom/logo.svg";
// import GenImg1 from "../assets/img/custom/avatar.png";
import getUserProfileImage from "../utils/getUserProfileImage";
import NavbarToggle from "../assets/img/custom/navbar-toggler-2.svg";
// import LogoutSmIcon from "../assets/img/custom/logout-icon.svg";
import Notifications from "../components/Notifications";
import { markNotificationsAsRead as markNotificationsAsReadApi } from "../api/user";

export default function Navbar() {
  const navigate = useNavigate();
  const { currentUser, setUser } = useContext(AuthContext);
  const [navbarOpen, setnavbarOpen] = useState(false);
  const { setAlert } = useAlert();

  // const { howToCreateRef } = useContext(AuthContext);

  const scrollToView = () => {
    navigate("/#howToCreate", {
      state: {
        currPath: window.location.pathname,
      },
    });
  };

  const handleLogout = () => {
    setnavbarOpen(false);
    logoutApi()
      .then(() => {
        setUser(null);
        navigate("/login");
        // window.location.reload();
        // setAlert("Logged out successfully", "success");
      })
      .catch((error) => {
        setAlert(error.response.data.message, "danger");
      });
  };

  const markAsRead = () => {
    markNotificationsAsReadApi();
  };

  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg navbar-light bg-white w-100 ${
          navbarOpen && "navbar-open"
        }`}
        style={{
          zIndex: 100,
          background: "white",
          padding: 0,
          height: "80px",
        }}
      >
        <div className="container-fluid">
          {!navbarOpen && (
            <NavLink className="navbar-brand" to="/">
              <div
                className="d-flex align-items-center"
                style={{ marginRight: "5rem" }}
              >
                <img
                  src={Logo}
                  className="navbar-brand-img"
                  style={{ height: "40px", marginRight: "10px", width: "100%" }}
                  alt="..."
                />
                <span
                  className="position-absolute"
                  style={{
                    marginLeft: "3rem",
                    fontWeight: 600,
                    fontSize: "22px",
                    lineHeight: "152.34%",
                    color: "#000000",
                  }}
                >
                  Memolast
                </span>
              </div>
            </NavLink>
          )}
          {!navbarOpen && (
            <div className="d-flex justify-content-end align-items-center">
              {currentUser && (
                <div className="d-block d-lg-none" onClick={markAsRead}>
                  <Notifications />
                </div>
              )}
              <button
                className="navbar-toggler p-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setnavbarOpen(!navbarOpen)}
              >
                <img
                  src={NavbarToggle}
                  alt="..."
                  style={{ height: "28.5px", width: "28.5px" }}
                />
              </button>
            </div>
          )}
          <div
            className={`collapse navbar-collapse m-0 ${navbarOpen && "show"}`}
            id="navbarCollapse"
          >
            <ul className="navbar-nav m-auto mt-0 pt-0">
              <li className="nav-item dropdown d-block d-lg-none">
                <NavLink className="navbar-brand" to="/">
                  <div
                    className="d-flex align-items-center"
                    style={{ marginRight: "5rem" }}
                    onClick={() => setnavbarOpen(!navbarOpen)}
                  >
                    <img
                      src={Logo}
                      className="navbar-brand-img"
                      style={{ height: "40px", marginRight: "10px" }}
                      alt="..."
                    />
                    <span
                      className="position-absolute"
                      style={{
                        marginLeft: "3rem",
                        fontWeight: 600,
                        fontSize: "22px",
                        lineHeight: "152.34%",
                        color: "#000000",
                      }}
                    >
                      Memolast
                    </span>
                  </div>
                </NavLink>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarCollapse"
                  aria-controls="navbarCollapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => setnavbarOpen(!navbarOpen)}
                >
                  <i className="fe fe-x"></i>
                </button>
              </li>
              <li className="nav-item dropdown">
                {currentUser ? (
                  <NavLink className="nav-link" to="/create-event">
                    <span
                      className="d-block d-lg-none"
                      style={{ paddingTop: "1px" }}
                      data-bs-toggle="collapse"
                      onClick={() => setnavbarOpen(false)}
                      data-bs-target="#navbarCollapse"
                      aria-controls="navbarCollapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      New Event
                    </span>
                    <span className="d-none d-lg-block">New Event</span>
                  </NavLink>
                ) : (
                  <NavLink className="nav-link" to="/example-event">
                    <span
                      className="d-block d-lg-none"
                      data-bs-toggle="collapse"
                      onClick={() => setnavbarOpen(false)}
                      data-bs-target="#navbarCollapse"
                      aria-controls="navbarCollapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      Example
                    </span>
                    <span className="d-none d-lg-block">Example</span>
                  </NavLink>
                )}
              </li>
              {currentUser ? (
                <li className="nav-item dropdown">
                  <NavLink className="nav-link" to="/events">
                    <span
                      className="d-block d-lg-none"
                      data-bs-toggle="collapse"
                      onClick={() => setnavbarOpen(false)}
                      data-bs-target="#navbarCollapse"
                      aria-controls="navbarCollapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      Events
                    </span>
                    <span className="d-none d-lg-block">Events</span>
                  </NavLink>
                </li>
              ) : (
                <li className="nav-item dropdown">
                  <div className="nav-link pointer" onClick={scrollToView}>
                    <span
                      className="d-block d-lg-none"
                      data-bs-toggle="collapse"
                      onClick={() => setnavbarOpen(false)}
                      data-bs-target="#navbarCollapse"
                      aria-controls="navbarCollapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      How it Works
                    </span>
                    <span className="d-none d-lg-block">How it Works</span>
                  </div>
                </li>
              )}
              <li className="nav-item dropdown">
                {currentUser ? (
                  <NavLink className="nav-link" to="/favorites">
                    <span
                      className="d-block d-lg-none"
                      data-bs-toggle="collapse"
                      onClick={() => setnavbarOpen(false)}
                      data-bs-target="#navbarCollapse"
                      aria-controls="navbarCollapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      Favorites
                    </span>
                    <span className="d-none d-lg-block">Favorites</span>
                  </NavLink>
                ) : (
                  <NavLink className="nav-link" to="/pricing">
                    <span
                      className="d-block d-lg-none"
                      data-bs-toggle="collapse"
                      onClick={() => setnavbarOpen(false)}
                      data-bs-target="#navbarCollapse"
                      aria-controls="navbarCollapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      Pricing
                    </span>
                    <span className="d-none d-lg-block">Pricing</span>
                  </NavLink>
                )}
              </li>
              <li className="nav-item dropdown border-bottom border-lg-0">
                <NavLink className="nav-link" to="/blog">
                  <span
                    className="d-block d-lg-none"
                    data-bs-toggle="collapse"
                    onClick={() => setnavbarOpen(false)}
                    data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Blog
                  </span>
                  <span className="d-none d-lg-block">Blog</span>
                </NavLink>
              </li>
              {currentUser && (
                <li className="nav-item dropdown d-block d-lg-none">
                  <NavLink className="nav-link" to="/account-settings">
                    <div onClick={() => setnavbarOpen(false)}>
                      <span
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        My Account
                      </span>
                    </div>
                  </NavLink>
                </li>
              )}
              {currentUser && (
                <li className="nav-item dropdown d-block d-lg-none pointer">
                  <div
                    onClick={handleLogout}
                    className="d-flex align-items-center nav-link"
                  >
                    <svg
                      className="logout-icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 17L21 12L16 7"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M21 12H9"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    {/* <img src={LogoutSmIcon} /> */}
                    <span
                      className="mx-1"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarCollapse"
                      aria-controls="navbarCollapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      Logout
                    </span>
                  </div>
                </li>
              )}
            </ul>

            <div className="nav-right">
              {!currentUser && (
                <div>
                  <NavLink
                    className="navbar-btn btn btn-sm ms-auto"
                    to="/login"
                  >
                    <span
                      className="d-block d-lg-none"
                      data-bs-toggle="collapse"
                      onClick={() => setnavbarOpen(false)}
                      data-bs-target="#navbarCollapse"
                      aria-controls="navbarCollapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      Login
                    </span>
                    <span className="d-none d-lg-block">Login</span>
                  </NavLink>
                  <NavLink
                    className="signup-btn navbar-btn btn btn-sm btn-primary ms-auto"
                    to="/signup"
                  >
                    <span
                      className="d-block d-lg-none"
                      data-bs-toggle="collapse"
                      onClick={() => setnavbarOpen(false)}
                      data-bs-target="#navbarCollapse"
                      aria-controls="navbarCollapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      Signup
                    </span>
                    <span className="d-none d-lg-block">Signup</span>
                  </NavLink>
                </div>
              )}
              {currentUser && (
                <div className="d-none d-lg-block">
                  <ul className="navbar-nav justify-content-center align-items-center">
                    <li onClick={markAsRead}>
                      <Notifications />
                    </li>
                    <li className="nav-item dropdown">
                      <NavLink className="nav-link p-0" to="/account-settings">
                        <img
                          alt="..."
                          className="profile-pic ms-2"
                          src={getUserProfileImage(currentUser)}
                          style={{ height: "42px", width: "42px" }}
                        ></img>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
