import { createContext, useState } from "react";

import React from "react";

export const EventContext = createContext();
export default function EventConTextProvider({ children }) {
  const [currSortOrder, setcurrSortOrder] = useState(0);
  const [currFavData, setcurrFavData] = useState(null);
  const [savedEvents, setsavedEvents] = useState([]);
  const [currEvent, setcurrEvent] = useState({
    eventAddress: null,
    media: [],
    skip: 0,
    windowScroll: 0,
  });
  const exampleEvent = "OUN-896";
  const value = {
    currSortOrder,
    setSortOrderIndex: setcurrSortOrder,
    currFavData,
    setcurrFavData,
    savedEvents,
    setsavedEvents,
    currentEvent: currEvent,
    setcurrentEvent: setcurrEvent,
    exampleEvent,
  };
  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
}
