import React from "react";
import { NavLink } from "react-router-dom";

// import "../assets/styles/app.css";

export default function Footer() {
  return (
    <div className="footer-section d-md-flex align-items-center justify-content-between">
      <div className="mb-3 mb-md-0 me-md-4 pe-md-4">&#169; Memolast 2022</div>
      <ul className="list-unstyled d-flex mb-0 footer-ul">
        <li className="border_right">
          <NavLink to="/events">
            <p className="m-0">Events</p>
          </NavLink>
        </li>
        <li className="border_right">
          <NavLink to="/">
            <p className="m-0">Privacy</p>
          </NavLink>
        </li>
        <li className="border_right">
          <NavLink to="/terms">
            <p className="m-0">Terms of Service</p>
          </NavLink>
        </li>
        <li className="border_right">
          <NavLink to="/pricing">
            <p className="m-0">Pricing</p>
          </NavLink>
        </li>
        <li>
          <NavLink to="/">
            <p className="m-0">About</p>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
